<template>
	<div class="content flex-space-between">
		<router-view></router-view>
	</div>
</template>

<script>

export default {
	data() {
		return {}
	},
	methods: {},
}
</script>

<style lang="less" scoped>

</style>
